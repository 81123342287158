import React, {useContext, useEffect, useMemo} from 'react';

import {map, pathOr, prop} from 'ramda';
import {
  useTaskByCurrentItemId,
  useViewerNavigation,
} from 'modules/viewer-v2/hooks';

import {Flex} from '@renofi/components-internal';
import {House} from '@renofi/icons';
import {basic80} from '@renofi/theme';
import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../../context';
import {
  getPropertiesByTask,
  getPropertyByDocumentId,
  getTaskDocumentsByPropertyId,
} from '../utils';

import ItemsFilterBadge from './ItemsFilterBadge/ItemsFilterBadge';

const PropertyTabs = () => {
  const {getItemIdByDirection, onItemsFilterIdsUpdate} =
    useContext(ViewerV2Context);
  const {project, task} = useTaskByCurrentItemId();
  const {history, search} = useViewerNavigation();
  const {documentId, propertyId} = search || {};

  const {streetAddressOne} = project || {};
  const {realEstateOwned} = task || {};
  const {subjectProperty} = realEstateOwned || {};

  const subjectPropertyAddress = {
    id: subjectProperty?.id,
    streetAddressOne,
  };

  useEffect(() => {
    (() => {
      let documents, property, to;

      switch (true) {
        case !propertyId && !!documentId:
          property = getPropertyByDocumentId({documentId, task});
          to = queryString.stringify({
            ...search,
            propertyId: property?.id,
          });
          return history.replace(`?${to}`);

        case !!propertyId && !documentId:
          documents = getTaskDocumentsByPropertyId({
            propertyId,
            task,
          });
          to = queryString.stringify({
            ...search,
            documentId: getItemIdByDirection(documents),
          });
          return history.replace(`?${to}`);

        case !propertyId && !documentId:
          [property] = getPropertiesByTask(task);
          documents = getTaskDocumentsByPropertyId({
            propertyId: property?.id,
            task,
          });
          to = queryString.stringify({
            ...search,
            propertyId: property?.id,
            documentId: getItemIdByDirection(documents),
          });
          return history.replace(`?${to}`);
        default:
          return false;
      }
    })();
  }, []);

  const tabs = useMemo(() => {
    const {additionalProperties = [], soldProperties = []} =
      realEstateOwned || {};
    const allProperties = [
      subjectPropertyAddress,
      ...additionalProperties,
      ...soldProperties,
    ];
    if (!allProperties.length) {
      return [];
    }
    return allProperties.map(({streetAddressOne, id}) => {
      return {
        label: streetAddressOne,
        value: id,
      };
    });
  }, [realEstateOwned, subjectPropertyAddress]);

  useEffect(() => {
    onItemsFilterIdsUpdate('propertyId', map(prop('value'), tabs));
    return () => {
      onItemsFilterIdsUpdate();
    };
  }, [tabs.length]);

  const onClickTab = (key, value) => {
    const documents = getTaskDocumentsByPropertyId({propertyId: value, task});
    const documentId = pathOr(undefined, [0, 'id'], documents);

    const to = queryString.stringify({
      ...search,
      documentId,
      [key]: value,
    });
    return history.push(`?${to}`);
  };

  return (
    <Flex px={2} py={3} backgroundColor={basic80}>
      {tabs.map(({label, value}) => {
        return (
          <ItemsFilterBadge
            active={propertyId === value}
            icon={House}
            key={value}
            onClick={onClickTab}
            task={task}
            value={value}>
            {label}
          </ItemsFilterBadge>
        );
      })}
    </Flex>
  );
};

export default PropertyTabs;
