import {taskTypes} from '@renofi/utilities/src/enums';

export const APPRAISAL_RECENT_TRANSACTIONS = 'appraisal_recent_transactions';
export const APPRAISAL_REPORT_DOCUMENT_TYPE = 'appraisal_report_document';
export const EMPLOYMENT_HISTORY_TASK_TYPE = taskTypes.employment_history;
const INSURANCE_COVERAGE_PROOF = 'insurance_coverage_proof';
export const PERSONAL_INFO_TASK_TYPE = taskTypes.personal_info_and_residency;
export const REAL_ESTATE_TASK_TYPE = taskTypes.real_estate_owned;

export const DOCUMENT_TASK_TYPES = {
  additional_documents: 'additional_documents',
  mortgage_statement: 'mortgage_statement',
  renovation_contract: 'renovation_contract',
  renovation_plan: 'renovation_plan',
  contractor_info: 'contractor_info',
  home_insurance: 'home_insurance',
  [INSURANCE_COVERAGE_PROOF]: 'insurance_coverage_proof',
  certification_and_authorization: 'certification_and_authorization',
  government_issued_id: taskTypes.government_issued_id,
  income: taskTypes.income,
  assets: taskTypes.assets,
};

export const NON_DOCUMENT_TASK_TYPES = {
  appraisal_comparable_sales: 'appraisal_comparable_sales',
  appraisal_property_details: 'appraisal_property_details',
  appraisal_property_photos: 'appraisal_property_photos',
  appraisal_renovation_details: 'appraisal_renovation_details',
  sms_consent_renovation_progress: 'sms_consent_renovation_progress',
  personal_info_and_residency: taskTypes.personal_info_and_residency,
  employment_history: taskTypes.employment_history,
  real_estate_owned: taskTypes.real_estate_owned, // TODO: remove
};

export const APPROVABLE_TASK_TYPES = [
  NON_DOCUMENT_TASK_TYPES.appraisal_comparable_sales,
];

export const FEASIBILITY_TASK_TYPES = [
  'appraisal_property_photos',
  'renovation_contract',
  'renovation_plan',
];

export const FEASIBILITY_STUDY_ITEM_TYPES = {
  IN_SCOPE: 'in_scope',
  OUT_OF_SCOPE: 'out_of_scope',
  HOMEOWNER_RESPONSIBILITY: 'homeowner_responsibility',
};

export const VIEWER_DATA_TYPES = [
  'task',
  'appraisal_report',
  'feasibility_review',
  'gcdd_review',
];

export const GCDD_REVIEW_TABS = {
  contractor: 'Contractor',
  questionnaire: 'Contractor Questionnaire',
  licenses: 'State license',
  insurances: 'Insurance',
  references: 'Reference questionnaires',
  reviews: 'Web reviews',
  bbb: 'Better Business Bureau',
};

export const COBORROWER_TASK_TYPES = [
  taskTypes.government_issued_id,
  taskTypes.income,
  taskTypes.assets,
  taskTypes.personal_info_and_residency,
];

export const TAB_TYPES = {
  document: 'document',
  gcddReview: 'gcdd_review',
  feasibility: 'feasibility',
  task: 'task',
};

export const REQUIRED_INSURANCE_FIELDS = [
  'carrier',
  'dwellingCoverage',
  'policyDateVisible',
  'policyEffectiveDate',
];
